<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-13 22:02:25
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-19 00:01:54
-->
<template>
  <div class="page-box">
    <div class="img__title">维修照片:</div>
    <div class="imgs flex-x center wrap">
      <div class="img-box img" @click="selectImg('photo')">
        <img :src="addImg" alt="" />
      </div>
      <div
        class="img-box img"
        v-for="(item, index) in photoDisplay"
        :key="index"
      >
        <img :src="item" alt="" />
        <div class="form__img__del" @click="delImg('photo', index)"></div>
      </div>
    </div>

    <div class="img__title">维修报告:</div>
    <div class="imgs flex-x center wrap">
      <div class="img-box img" @click="selectImg('report')">
        <img :src="addImg" alt="" />
      </div>
      <div
        class="img-box img"
        v-for="(item, index) in reportDisplay"
        :key="index"
      >
        <img :src="item" alt="" />
        <div class="form__img__del" @click="delImg('report', index)"></div>
      </div>
    </div>

    <div class="btn" @click="submit">确认提交</div>
  </div>
</template>

<script>
import { chooseImgs, getDisplayImgs, uploadImgs } from "../../../utils/wxTools";
import { submitReport } from "../../../utils/api/service";
import { Toast } from "vant";
export default {
  data() {
    return {
      addImg: require("../../../assets/images/review/form_addimg.png"),

      maxLen: 3,

      photo: [], // 维修照片
      photoDisplay: [],
      report: [], // 微信报告
      reportDisplay: [],
    };
  },

  methods: {
    // 选择照片
    selectImg(key) {
      chooseImgs(this.maxLen - this[key].length).then((res) => {
        this[key].push(...res);
        getDisplayImgs(res).then((res) => {
          this[key + "Display"].push(...res);
        });
      });
    },

    // 删除照片
    delImg(key, index) {
      this[key].splice(index, 1);
      this[key + "Display"].splice(index, 1);
    },

    // 确认提交
    async submit() {
      const photos = await uploadImgs(this.photo);
      const reports = await uploadImgs(this.report);
      submitReport({
        id: this.$route.query.id,
        photo: photos,
        report: reports,
      })
        .then((res) => {
          Toast.success({
            message: "报告成功",
            onClose: function () {
              this.$router.back();
            }.bind(this),
          });
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },
  },
};
</script>

<style src="../../../assets/css/service/report.css" scoped>
</style>